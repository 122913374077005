import { lazy } from 'react'
import { Redirect } from 'react-router'
import { RouteConfig } from 'react-router-config'

const routes: RouteConfig[] = [
  {
    path: '/',
    component: lazy(() => import('@/pages/lna/base-layout')),
    routes: [
      {
        path: '/',
        component: lazy(() => import('@/pages/lna/official/pc-site')),
        exact: true,
      },
      {
        path: '/champion1',
        component: lazy(() => import('@/pages/lna/activities/champion1')),
        exact: true,
      },
      {
        path: '/odin',
        component: lazy(() => import('@/pages/lna/odin')),
        exact: true,
      },
      {
        path: '/reserve',
        component: lazy(() => import('@/pages/lna/reserve')),
        exact: true,
      },
      {
        path: '/reserve/duo',
        component: lazy(
          () => import('@/pages/lna/reserve/pages/duo-reserve/index')
        ),
        exact: true,
      },
      {
        path: '/reserve/group',
        component: lazy(
          () => import('@/pages/lna/reserve/pages/group-reserve/index')
        ),
        exact: true,
      },
      {
        path: '/reserve/team/invite',
        component: lazy(
          () => import('@/pages/lna/reserve/pages/team-reserve-main/index')
        ),
        exact: true,
      },
      {
        path: '/reserve/team',
        component: lazy(
          () => import('@/pages/lna/reserve/pages/team-reserve/index')
        ),
        exact: true,
      },
      {
        path: '/activity/sakura2024',
        component: lazy(() => import('@/pages/sea/activities/sakura2024')),
        exact: true,
      },
      {
        path: '/home',
        component: lazy(() => import('@/pages/lna/official/pc-site')),
        exact: true,
      },
      {
        path: '/benefits',
        component: lazy(() => import('@/pages/lna/benefits')),
        exact: true,
      },
      {
        path: '/m/benefits',
        component: lazy(() => import('@/pages/lna/benefits')),
        exact: true,
      },
      {
        path: '/news',
        component: lazy(
          () => import('@/pages/lna/official/pc-site/subpages/news-home')
        ),
        exact: true,
      },
      {
        path: '/news/:id',
        component: lazy(
          () => import('@/pages/lna/official/pc-site/subpages/news-detail')
        ),
        exact: true,
      },
      {
        path: '/reservation/sakura2024',
        component: lazy(() => import('@/pages/sea/reservation/sakura2024')),
        exact: true,
      },
      {
        path: '/third-job-act',
        component: lazy(
          () => import('@/pages/lna/activities/third_job_act/pc-site')
        ),
      },
      {
        path: '/activity/awaken2024',
        component: lazy(() => import('@/pages/lna/activities/awaken2024')),
        exact: true,
      },
      {
        path: '/authcallback',
        component: lazy(() => import('@/pages/lna/authcallback')),
        exact: true,
      },
      {
        path: '/discord/login-bind',
        component: lazy(() => import('@/pages/sea/discord_login')),
        exact: true,
      },
      // {
      //   path: '/transit',
      //   exact: true,
      //   component: lazy(() => import('@/pages/lna/pay/pc/transit')),
      // },
      // 官网支付
      // {
      //   path: '/shopStore',
      //   component: lazy(() => import('@/pages/lna/pay_new/pc')),
      //   routes: [
      //     {
      //       path: '/shopStore/selectOrder',
      //       component: lazy(() => import('@/pages/lna/pay_new/pc/giftList')),
      //     },
      //     {
      //       path: '/shopStore/help-center',
      //       component: lazy(() => import('@/pages/lna/pay_new/pc/helpCenter')),
      //     },
      //     {
      //       path: '/shopStore/pay',
      //       component: lazy(() => import('@/pages/lna/pay_new/pc/pay')),
      //     },
      //     {
      //       path: '/shopStore/wait-order',
      //       component: lazy(() => import('@/pages/lna/pay_new/pc/waitOrder')),
      //     },
      //     {
      //       path: '/shopStore/fail-order',
      //       component: lazy(() => import('@/pages/lna/pay_new/pc/failOrder')),
      //     },
      //   ],
      // },
      {
        path: '/m',
        component: lazy(() => import('@/pages/lna/components/empty-layout')),
        routes: [
          // 封面
          {
            path: '/m',
            component: lazy(() => import('@/pages/lna/official/m-site')),
            exact: true,
          },
          {
            path: '/m/third-job-act',
            component: lazy(
              () => import('@/pages/lna/activities/third_job_act/m-site')
            ),
            exact: true,
          },
          {
            path: '/m/champion1',
            component: lazy(() => import('@/pages/lna/activities/champion1')),
          },
          {
            path: '/m/home',
            component: lazy(() => import('@/pages/lna/official/m-site')),
          },
          {
            path: '/m/news',
            component: lazy(
              () => import('@/pages/lna/official/m-site/subpages/news-home')
            ),
            exact: true,
          },
          {
            path: '/m/news/:id',
            component: lazy(
              () => import('@/pages/lna/official/m-site/subpages/news-detail')
            ),
            exact: true,
          },
          {
            path: '/m/activity/sakura2024',
            component: lazy(
              () => import('@/pages/sea/activities/sakura2024/m-site')
            ),
            exact: true,
          },
          {
            path: '/m/activity/awaken2024',
            component: lazy(() => import('@/pages/lna/activities/awaken2024')),
            exact: true,
          },
          {
            path: '/m/authcallback',
            component: lazy(() => import('@/pages/lna/authcallback')),
            exact: true,
          },
          // {
          //   path: '/m/transit',
          //   exact: true,
          //   component: lazy(() => import('@/pages/lna/pay/mobile/transit')),
          // },
          /* 支付--mobile */
          // {
          //   path: '/m/shopStore',
          //   component: lazy(() => import('@/pages/lna/pay_new/mobile')),
          //   routes: [
          //     {
          //       path: '/m/shopStore/selectOrder',
          //       component: lazy(
          //         () => import('@/pages/lna/pay_new/mobile/giftList')
          //       ),
          //     },
          //     {
          //       path: '/m/shopStore/help-center',
          //       component: lazy(
          //         () => import('@/pages/lna/pay_new/mobile/helpCenter')
          //       ),
          //     },
          //     {
          //       path: '/m/shopStore/pay',
          //       component: lazy(() => import('@/pages/lna/pay_new/mobile/pay')),
          //     },
          //     {
          //       path: '/m/shopStore/wait-order',
          //       component: lazy(
          //         () => import('@/pages/lna/pay_new/mobile/waitOrder')
          //       ),
          //     },
          //     {
          //       path: '/m/shopStore/fail-order',
          //       component: lazy(
          //         () => import('@/pages/lna/pay_new/mobile/failOrder')
          //       ),
          //     },
          //   ],
          // },
          {
            path: '*',
            render: () => <Redirect to="/m/home" />,
          },
        ],
      },
      {
        path: '*',
        render: () => <Redirect to="/home" />,
      },
    ],
  },
]

export default routes
